<template>
  <SimpleSite title="Datenschutz">
    <div style="color: black">
      <h1>Datenschutzerklärung</h1>
      <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
        ist:</p>
      <p></p>
      <h2>Ihre Betroffenenrechte</h2>
      <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
        ausüben:</p>
      <ul>
        <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht
          löschen dürfen (Art. 18 DSGVO),
        </li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
        <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
          abgeschlossen haben (Art. 20 DSGVO).
        </li>
      </ul>
      <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
        widerrufen.</p>
      <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
        Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
        Behörde.</p>
      <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a
          href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank"
          rel="noopener nofollow">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.</p>
      <p></p>
      <h2>Kontaktformular</h2>
      <h3>Art und Zweck der Verarbeitung:</h3>
      <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert.
        Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung
        der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
      <h3>Rechtsgrundlage:</h3>
      <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten
        Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
      <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen.
        Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
        gespeichert.</p>
      <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
        Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
      <h3>Empfänger:</h3>
      <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
      <p></p>
      <h3>Speicherdauer:</h3>
      <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
      <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und
        löschen Ihre Daten nach Ablauf dieser Fristen. </p>
      <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
      <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur
        bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
      <p></p>
      <h2>Verwendung von Google Analytics</h2>
      <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein
        Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“).
        Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die
        eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über
        Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von
        Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
        über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
        Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
        übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. </p>
      <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a
          href="https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de"
          rel="noopener" target="_blank">https://www.google.com/analytics/terms/de.html und unter
        https://policies.google.com/?hl=de</a>. </p>
      <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite
        auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. </p>
      <p>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden
        nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt
        automatisch einmal im Monat.</p>
      <h3>Widerruf der Einwilligung:</h3>
      <p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie <a
          title="Google Analytics Opt-Out-Cookie setzen"
          onClick="gaOptout();alert('Google Analytics wurde deaktiviert');" href="#">diesen Link anklicken</a>. Dabei
        wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese
        Website und für diesen Browser zukünftig verhindert, solange das Cookie in Ihrem Browser installiert bleibt.</p>
      <p>Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
        Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. </p>
      <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
        bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a
            href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener" target="_blank">Browser Add On zur
          Deaktivierung von Google Analytics</a>.</p>
      <p></p>
      <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
      <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser
        Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend
        „Google“) zur Darstellung von Schriften.</p>
      <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
                                                                       rel="noopener nofollow" target="_blank">https://developers.google.com/fonts/faq</a>
        und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/"
                                                       rel="noopener nofollow" target="_blank">https://www.google.com/policies/privacy/</a>.
      </p>
      <p></p>
      <h2>Verwendung von Google Maps</h2>
      <p>Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen interaktive
        Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.<br>Nähere
        Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen: <a
            href="https://policies.google.com/privacy" rel="noopener nofollow" target="_blank">https://policies.google.com/privacy</a>.
        Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
      <p>Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie hier:
        <a href="https://www.dataliberation.org/" rel="noopener nofollow"
           target="_blank">https://www.dataliberation.org</a></p>
      <p>Durch den Besuch der Website erhält Google Informationen, dass Sie die entsprechende Unterseite unserer
        Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie
        eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten
        direkt Ihrem Konto zugeordnet. </p>
      <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons
        bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung,
        Marktforschung und/oder bedarfsgerechter Gestaltung seiner Websites. Eine solche Auswertung erfolgt insbesondere
        (selbst für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen
        Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen
        die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. </p>
      <h3>Widerruf der Einwilligung:</h3>
      <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der
        Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
        wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie
        oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website
        jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
      <p></p>
      <h2>SSL-Verschlüsselung</h2>
      <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
        entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
      <p></p>
      <hr>
      <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
      <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
      <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
        Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
        (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch
        für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
      <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
        können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen.</p>
      <h3>Empfänger eines Widerspruchs</h3>
      <p></p>
      <hr>
      <h2>Änderung unserer Datenschutzbestimmungen</h2>
      <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
        Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei
        der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
      <h2>Fragen an den Datenschutzbeauftragten</h2>
      <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die
        für den Datenschutz verantwortliche Person in unserer Organisation:</p>
      <p></p>
      <p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
          href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe
        Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>
    </div>
  </SimpleSite>
</template>

<script>
import SimpleSite from "../components/SimpleSite";

export default {
  components: {SimpleSite}
}
</script>
