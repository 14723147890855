<template>
  <div class="simple_site">
    <p class="simple_site--title">{{ title }}</p>
    <div class="simple_site--text">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleSite',
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">


.simple_site {
  background-color: #343433;
  padding-bottom: 60px;

  &--title {
    text-align: center;
    color: white;
    margin: 0;
    padding-top: 45px;
    padding-bottom: 30px;
    font-family: Monte, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }

  &--text {
    margin: 0 auto;
    padding: 60px;
    border-radius: 10px;
    background-color: #FAFAFA;
    max-width: 80%;
    font-style: normal;
    font-weight: bold;
    font-family: Monte, serif;
    font-size: 18px;
    line-height: 22px;
  }
}

</style>